import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({ namespaced: true })
class SidebarModule extends VuexModule {
  isMini = true

  counter: any;


  public get counterValue() {
    return this.counter;
  }

  @Mutation
  setIsMini(isMini: boolean) {
    this.isMini = isMini
  }

  @Mutation
  setCounterValue(value: number) {
    this.counter = value;
  }



}

export default SidebarModule
